
import Detail from "./detail.vue";
import { useSelectItemList } from "@/network/lookUp.ts";
import {
  defineComponent,
  inject,
  ref,
  onMounted,
  reactive,
  toRefs,
  toRef,
} from "vue";
import { useRoute } from "vue-router";
import { useQueryHeritages } from "@/network/heritageUnit.ts";
import { useCityRelTown } from "@/network/chinaArea";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, Detail },
  setup() {
    const user: any = inject("user");
    const id = ref(0);
    const route = useRoute();
    const setting = reactive({ id: id, visible: false });
    const filter = reactive({
      isUnit: null,
      pageNum: 1,
      pageSize: 20,
      keyword: null,
      city: null,
      town: null,
      pcode: null,
      publictype: null,
      age: null,
      creator: user.id,
    });

    const [dicCity, dicTown] = useCityRelTown(
      toRef(filter, "city"),
      toRef(filter, "town")
    );

    const dicHeritageAge = useSelectItemList("HeritageAge");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");

    const showDetail = (_id) => {
      id.value = _id;
      setting.visible = true;
      return false;
    };
    const [isLoading, pagerData, query] = useQueryHeritages(filter);
    onMounted(() => {
      query();
    });
    return {
      user,

      showDetail,
      id,
      setting,
      dicCity,
      dicTown,
      dicHeritageClass,
      dicHeritageAge,
      dicHeritageBatch,
      isLoading,
      pagerData,
      query,
      filter,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
